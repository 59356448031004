@font-face {
  font-family: "Praktikal";
  src: url("/fonts/praktikal-thin.otf") format("opentype");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "Praktikal";
  src: url("/fonts/praktikal-light.otf") format("opentype");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Praktikal";
  src: url("/fonts/praktikal-regular.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Praktikal";
  src: url("/fonts/praktikal-medium.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Praktikal";
  src: url("/fonts/praktikal-semibold.otf") format("opentype");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Praktikal";
  src: url("/fonts/praktikal-bold.otf") format("opentype");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Praktikal";
  src: url("/fonts/praktikal-extrabold.otf") format("opentype");
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: "Praktikal";
  src: url("/fonts/praktikal-black.otf") format("opentype");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex";
  src: url("/fonts/IBMPlexSans-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex";
  src: url("/fonts/IBMPlexSans-Regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex";
  src: url("/fonts/IBMPlexSans-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}
